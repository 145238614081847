<template>
  <div class="AuxiliaryReading">
    <div class="con_wrap">
      <div class="con_box">
        <el-button
          type="text"
          icon="el-icon-arrow-left"
          @click="$router.back(-1)"
          >返回上一页</el-button
        >
        <div
          class="text"
          v-html="DivisionContentByContentId.common_content"
        ></div>
      </div>
    </div>
    <!-- <foot></foot> -->
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import { getDivisionContentByContentId } from "@api/public";
export default {
  components: {},
  data() {
    return {
      DivisionContentByContentId: {},
    };
  },
  mounted: function () {
    let that = this;
    getDivisionContentByContentId(that.queryWhere).then(function (res) {
      that.$set(that, "DivisionContentByContentId", res.data);
    });
    scroll($(".nice-scroll"));
  },
  created: function () {
    this.queryWhere = this.$route.query;
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.AuxiliaryReading .con_wrap {
  width: 100%;
  height: 900px;
  background: url(../../assets/images/fuzhu.jpg);
  /* background: #faead1; */
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.AuxiliaryReading .con_box {
  width: 1314px;
  height: 700px;
  position: relative;
  top: 200px;
  padding: 50px 100px;
  margin: 0 auto;
  box-sizing: border-box;
}

.con_box .text {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.el-button--text {
  color: rgb(121, 0, 0);
}
.text::-webkit-scrollbar {
  width: 0 !important;
}

.text::-webkit-scrollbar {
  width: 0 !important;
}

.text p {
  letter-spacing: 3px !important;
  line-height: 3;
}
</style>